@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~primeng/resources/primeng.min.css';
@import '~primeflex/primeflex.css';

@import 'assets/styles/badges.scss';
@import 'assets/styles/code.scss';
@import 'assets/styles/flags/flags.css';
@import 'assets/styles/layout.scss';

:root {
    --bg-khaki: #f6f2f2;
}

.bg-khaki {
    background-color: var(--bg-khaki);
}

body {
    background-color: var(--surface-section);
}

.pi.chevron-up::before {
    content: '\f077';
    font-family: FontAwesome;
}

.pi-chevron-right::before {
    content: '\f054';
    font-family: FontAwesome;
}

.pi-chevron-down::before {
    content: '\f078';
    font-family: FontAwesome;
}

.pi-chevron-left::before {
    content: '\f053';
    font-family: FontAwesome;
}

.pi-angle-up::before {
    content: '\f106';
    font-family: FontAwesome;
}

.pi-angle-right::before {
    content: '\f105';
    font-family: FontAwesome;
}

.pi-angle-down::before {
    content: '\f107';
    font-family: FontAwesome;
}

.pi-angle-left::before {
    content: '\f104';
    font-family: FontAwesome;
}

.pi-angle-double-up::before {
    content: '\f102';
    font-family: FontAwesome;
}

.pi-angle-double-right::before {
    content: '\f101';
    font-family: FontAwesome;
}

.pi-angle-double-down::before {
    content: '\f103';
    font-family: FontAwesome;
}

.pi-angle-double-left::before {
    content: '\f100';
    font-family: FontAwesome;
}

.pi-sort-alt::before {
    content: '\f0dc';
    font-family: FontAwesome;
    font-style: normal;
}

.pi-times-circle::before {
    content: '\f057';
    font-family: FontAwesome;
    font-style: normal;
}

.p-dropdown-clear-icon::before,
.p-multiselect-close-icon::before {
    content: '\f00d';
    font-family: FontAwesome;
    font-style: normal;
}

.pi-plus::before {
    font-family: FontAwesome;
    content: '\2b';
}

.pi-minus::before {
    content: '\f068';
    font-family: FontAwesome;
}

.pi-eye::before {
    content: '\f06e';
    font-family: FontAwesome;
}

.pi-eye-slash::before {
    content: '\f070';
    font-family: FontAwesome;
}

.pi-filter::before {
    content: '\f0b0';
    font-family: FontAwesome;
}

.pi-sort-amount-up-alt::before {
    content: '\f885';
    font-family: FontAwesome;
    font-style: normal;
}

.pi-sort-amount-down::before {
    content: '\f161';
    font-family: FontAwesome;
    font-style: normal;
}

.p-icon pi-filter-icon::before {
    content: '\f0b0';
    font-family: FontAwesome;
    font-style: normal;
}

.p-column-filter-clear-button::before {
    content: '\e17b';
    font-family: FontAwesome;
    font-style: normal;
}

.p-button.p-button-xs {
    font-size: 0.581rem;
    padding: 0.394rem 0.581rem;
}

.p-button.p-button-xs .p-button-icon {
    font-size: 0.581rem;
}

.p-splitbutton.p-button-xs > .p-button {
    font-size: 0.581rem;
    padding: 0.394rem 0.581rem;
}

.p-splitbutton.p-button-xs > .p-button .p-button-icon {
    font-size: 0.581rem;
}

.p-input-icon-left > i:first-of-type {
    top: 44%;
}

eyeicon,
eyeslashicon {
    right: 10px;
}

.indicatorsContentClass {
    position: relative;
    bottom: 40px;
    display: flex;
    align-items: center;
}

.hero-image {
    height: 320px;
    width: 100%;
    filter: grayscale(40%);

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.login-card {
    background: none;
    border: 6px solid white;
    border-radius: 0;
    width: 100vw;
    height: 100vh;
}

.login-card-header {
    width: 100%;
    height: 33%;
    min-height: 240px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.login-card-header-hyundai {
    background-color: var(--brand-primary)-dark;
}

.login-card-header-dacia {
    background-color: var(--brand-primary);
}

.login-card-header-renault {
    background-color: var(--brand-primary);
}

.login-card-body {
    height: auto;
}

.login-card-footer {
    height: 10%;
    min-height: 75px;
}

.hyundai-background-light-color {
    background-color: var(--brand-lighter);
}

.dacia-background-light-color {
    background-color: var(--brand-secondary);
}

.renault-background-light-color {
    background-color: var(--brand-secondary);
}

.renault-background-light-color {
    background-color: var(--brand-lighter);
}

.hyundai-text-color {
    color: var(--brand-primary);
}

.hyundai-main-color {
    color: var(--brand-primary);
}

.hyundai-border-color {
    border-color: var(--brand-primary);
}

.dacia-text-color,
.dacia-main-color {
    color: var(--brand-primary);
}

.renault-text-color,
.renault-main-color {
    color: var(--brand-primary);
}

.nissan-text-color,
.nissan-main-color {
    color: var(--brand-primary);
}

.grid-no-bottom-padding {
    margin-bottom: -0.5rem;
}

.left-edge-shadow {
    box-shadow: -3px 0 3px 0 rgba(0, 0, 0, 0.08) !important;
}

@media screen and (min-width: 576px) {
    .sm\:login-card-header {
        opacity: 0.75;
    }

    .sm\:login-card-body {
        height: 58%;
        min-height: 435px;
    }

    .sm\:login-card {
        left: 10%;
        width: 480px;
        height: 100%;
        min-height: 750px;
        margin: 10vh 0 !important;

        @media (max-height: 1000px) {
            margin: 2vh 0 !important;
        }
    }
}

@media screen and (min-width: 576px) and (max-width: 1024px) {
    .sm\:login-card {
        margin: 0;
        left: 50%;
        transform: translate(-50%, 0%);
    }
}

.p-accordion-header {
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.p-card-header {
    text-transform: uppercase;

    & h5 {
        padding: 1.25rem;
    }
}

.footer-link {
    color: white;
    text-decoration: none;
}

.footer-link:hover,
.footer-link:focus,
.footer-link:visited {
    color: white;
}
