.layout-footer {
    transition: margin-left $transitionDuration;
}

.layout-footer .p-button.p-button-outlined {
    color: white !important;
}

.layout-footer .p-button.p-button-text {
    color: white !important;
}

.social-icon {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 3rem;
    margin-right: 1rem;
}

.social-icon i {
    transition: transform 0.2s;
}

.social-icon:hover i {
    transform: scale(1.1);
    opacity: 0.8;
}
